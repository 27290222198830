import React, { useState } from "react"
import { content, content_inner, featured_image } from "../assets/global"

import Image from "gatsby-image"
import Layout from "../components/layout"
import PageHeader from "../components/page_header"
import QuickMenu from "../components/quick_menu"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import sanitizeHtml from "sanitize-html"
import styled from "styled-components"
import { whr_blue } from "../assets/colours"

const ReferPage = ({ data }) => {
  const featuredImage = {
    fluid: data.wpPage.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: data.wpPage.featuredImage?.node?.alt || ``,
  }

  const quick_menu_links = data?.wpPage?.pageTemplateRefer?.referralSections.map(
    ({ referralName }) => {
      return {
        title: referralName,
        id: referralName.replace(/\s+/g, "-")?.toLowerCase(),
      }
    }
  )

  const [menuOpen, setMenuOpen] = useState(false)
  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  return (
    <Layout
      title={data.wpPage.seo.title}
      menuOpen={menuOpen}
      toggleMenu={toggleMenu}
      fullWidthMenu={false}
    >
      <Seo
        description={data.wpPage.seo.metaDesc}
        title={data.wpPage.seo.title}
      />
      <FeaturedImage>
        <Image
          fluid={featuredImage.fluid}
          alt={featuredImage.alt}
          style={{ height: "100%" }}
        />
      </FeaturedImage>
      <Content>
        <PageHeader title={data.wpPage.title} />
        {data?.wpPage?.pageTemplateRefer?.referralSections && (
          <QuickMenu
            jump_text="Jump To"
            menu_items={quick_menu_links}
          ></QuickMenu>
        )}
        <ContentInner>
          {data?.wpPage?.pageTemplateRefer?.referralSections?.map(
            ({ referralLink, referralIcon, referralName, referralText }) => {
              return (
                <ReferralSection
                  key={referralText}
                  id={referralName.replace(/\s+/g, "-")?.toLowerCase()}
                >
                  <h2>{referralName}</h2>
                  {referralLink && referralIcon && (
                    <a href={referralLink} target="_blank" rel="noreferrer">
                      <img
                        src={referralIcon?.localFile?.publicURL}
                        alt={referralIcon?.altText}
                      />
                    </a>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(referralText),
                    }}
                  ></div>
                </ReferralSection>
              )
            }
          )}
        </ContentInner>
      </Content>
    </Layout>
  )
}

export default ReferPage

const FeaturedImage = styled.div`
  ${featured_image};
`

const Content = styled.article`
  ${content};
`
const ContentInner = styled.div`
  ${content_inner};
  h2 {
    font-size: 2rem;
  }
`

const ReferralSection = styled.section`
  > h2 {
    color: ${whr_blue};
    margin-bottom: 1.5rem;
  }

  > a {
    display: block;
    margin-bottom: 1.5rem;
  }

  img {
    display: block;
    height: 15rem !important;
    width: auto;
    margin: 0 auto;
  }
`

export const pageQuery = graphql`
  {
    wpPage(template: { templateName: { eq: "Refer Template" } }) {
      seo {
        metaDesc
        title
      }
      id
      title
      slug
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      pageTemplateRefer {
        referralSections {
          referralLink
          referralName
          referralText
          referralIcon {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
